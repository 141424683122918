<template>
  <div class="p-4">
    <div class="register-box row m-0">

      <div class="col-md-6 col-12">
        <div class="logo mb-2">
          <img src="../assets/logo-desc.png" alt="">
        </div>

        <div class="form-box text-center">

          <div class="bottom my-5 ">
            <h4>در حال آماده سازی داشبورد شما</h4>
            <span>این فرایند ممکن است دقایقی زمان ببرد. لطفا صفحه را ریلود نکرده یا از صفحه خارج نشوید.</span>
          </div>
          <div class="circle-progress mt-5">
            <div class="percent">
              <h3><span>%</span>{{ percentage }}</h3>
            </div>
            <circle-progress
                empty-color="#f4f5f7"
                size="160"
                fill-color="#e2ecfe"
                background="#fff"
                :border-width="9"
                :border-bg-width="15"
                :percent="percentage"
                :is-gradient="true"
                :gradient="{
                      angle: 90,
                      startColor: '#CFBFFF',
                      stopColor: '#3300CC'
                  }"
            />

          </div>
          <div class="w-100 d-block">شروع بارگذاری</div>


        </div>

        <img class="bg" src="../assets/img/half-circle.png" alt="">
      </div>
      <div class="col-md-6 d-sm-block d-none">
        <div class="slider-box">
          <div class="slider-container">
            <div class="slider">
              <div class="card-slider">

                <div class="child">
                  <div class="image">
                    <img :src="slides[currentSlide].img" :alt="`Slide ${currentSlide + 1}`" class="slide"/>

                  </div>
                  <div class="text">
                    <h2>{{ slides[currentSlide].title }}</h2>
                    <p>{{ slides[currentSlide].des }}</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="navigation">
              <button
                  v-for="(slide, index) in slides"
                  :key="index"
                  @click="goToSlide(index)"
                  :class="{ active: currentSlide === index }"
              ></button>
            </div>
          </div>


        </div>
      </div>

    </div>
  </div>
</template>

<script>
import axios from "axios";
import store from "@/store";
import Cookies from "js-cookie";
import "vue3-circle-progress/dist/circle-progress.css";
import CircleProgress from "vue3-circle-progress";

export default {
  name: "LoadingView",
  components: {CircleProgress},
  data() {
    return {
      apiUrl: store.getters.baseUrl,
      loading: false,
      snackbar: false,
      textSnack: false,
      colorSnack: '',
      percentage: 50,
      count: 0,
      slides: [
        {
          img: new URL('@/assets/img/slider/slide1.png', import.meta.url).href,
          title: 'همراهی در خط مقدم سازمان',
          des: 'اجیستنت با هدف ساده‌تر کردن پاسخگویی به مشتریان طراحی شده و به شما کمک می‌کند تا با کیفیت بالاتر، هزینه کمتر و سرعت بیشتر به نیازهای مخاطبین خود پاسخ دهید.'
        },
        {
          img: new URL('@/assets/img/slider/slide2.png', import.meta.url).href,
          title: 'پشتیبانی از کانال‌های مختلف',
          des: 'فرقی نمی‌کند مشتریان از طریق تلفن، صندوق صوتی، پورتال، شبکه‌های اجتماعی یا پیامک با شما در ارتباط باشند، اجیستنت می‌تواند به همه این درگاه‌ها متصل شود و پاسخگویی را برای شما آسان‌تر کند.'
        },
        {
          img: new URL('@/assets/img/slider/slide3.png', import.meta.url).href,
          title: 'سازگاری با انواع سیستم‌های کال سنتر',
          des: 'اجیستنت به راحتی با سیستم‌های مختلف کال سنتر، چه نرم‌افزاری و چه سخت‌افزاری، هماهنگ می‌شود و به عنوان یک دستیار کارآمد در کنار شماست.'
        },
        {
          img: new URL('@/assets/img/slider/slide4.png', import.meta.url).href,
          title: 'انتقال درخواست‌ها در سطوح مختلف سازمان',
          des: 'با اجیستنت می‌توانید درخواست‌های مشتریان را به بخش‌های مختلف سازمان ارجاع دهید. کارشناسان و مدیران هر بخش به راحتی می‌توانند روی درخواست‌ها نظر بدهند و پاسخ‌های مناسب را ارائه کنند.'
        },
        {
          img: new URL('@/assets/img/slider/slide5.png', import.meta.url).href,
          title: 'یکپارچگی با سایر سامانه‌های سازمانی',
          des: 'اجیستنت به سامانه‌های مختلف سازمان متصل می‌شود و می‌تواند درخواست‌هایی مثل استعلام قیمت، زمان ارسال، پیشرفت پروژه و ... را به سرعت و بدون نیاز به دخالت انسانی پاسخ دهد.'
        },
        {
          img: new URL('@/assets/img/slider/slide6.png', import.meta.url).href,
          title: 'شخصی‌سازی برای نیازهای شما',
          des: 'اجیستنت این امکان را به شما می‌دهد که همه چیز را طبق نیازهای کسب و کار خود تنظیم کنید؛ از فرم‌ها و وضعیت درخواست‌ها گرفته تا گروه‌بندی‌ها و سطوح دسترسی.'
        },
        {
          img: new URL('@/assets/img/slider/slide7.png', import.meta.url).href,
          title: 'هوش مصنوعی در خدمت شما',
          des: 'اجیستنت با استفاده از هوش مصنوعی یاد می‌گیرد که چگونه به بهترین شکل به درخواست‌های مشتریان پاسخ دهد و بسیاری از آن‌ها را به صورت خودکار مدیریت می‌کند.'
        },
        {
          img: new URL('@/assets/img/slider/slide8.png', import.meta.url).href,
          title: 'رابط کاربری ساده و دوستانه',
          des: 'با اجیستنت، کار با نرم‌افزار پیچیده نیست. رابط کاربری ساده و ابزارهای کاربردی، تجربه کاربری راحتی را برای شما و تیم‌تان فراهم می‌کند.'
        },
        {
          img: new URL('@/assets/img/slider/slide9.png', import.meta.url).href,
          title: 'گزارش‌های مدیریتی برای تصمیم‌گیری بهتر',
          des: 'اجیستنت گزارش‌های متنوعی در اختیار شما قرار می‌دهد تا بتوانید عملکرد تیم پاسخگویی را به خوبی بررسی کنید و همیشه از وضعیت سازمان خود مطلع باشید. گزارش‌ها را می‌توانید بر اساس کارشناس، بخش یا گروه‌بندی دریافت کنید و تصمیمات بهتری بگیرید.'
        },
      ],
      currentSlide: 0,
      interval: null,
      model: null,
      intervalId: null,
      intervalId2: null,
      headers: {
        'Accept': 'Application/json',
        'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
      },
    }
  },
  methods: {
    nextSlide() {
      this.currentSlide = (this.currentSlide + 1) % this.slides.length;
    },
    goToSlide(index) {
      this.currentSlide = index;
      this.resetInterval();
    },
    resetInterval() {
      clearInterval(this.interval);
      this.startAutoSlide();
    },
    startAutoSlide() {
      this.interval = setInterval(this.nextSlide, 5000);
    },
    getCookie() {
      return Cookies.get('token');
    },
    animateNumbers(start, end) {
      let counter = start;
      const step = end > start ? 1 : -1; // جهت شمارش
      const interval = setInterval(() => {
        if (counter !== end) {
          counter += step;
          this.percentage = counter;
        } else {
          clearInterval(interval);
        }
      }, 50);
    },
    getNode() {
      let token = this.getCookie()
      let auth = 'Bearer ' + token
      this.headers.Authorization = auth
      axios.get('https://api.agistant.com/api/node-creation-status', {headers: this.headers})
          .then(res => {
            const nextNumber = Math.ceil(res.data.percentage)

            if (this.percentage < 100) {

              this.animateNumbers(this.percentage, nextNumber);
              this.percentage = nextNumber;
            } else {
              this.percentage = 100
              clearInterval(this.intervalId2);
              clearInterval(this.intervalId);
              Cookies.set('token', res.data.api_token,
                  {
                    path: '/',
                  }
              );
              localStorage.setItem('urlPanel', res.data.url)
              this.$router.push({name: 'Completed'})
            }

          }).catch(err => {
        if (err?.response?.status === 401) {
           this.$router.push({ name: 'Register'})

        }

      })
    }
  },
  beforeUnmount() {
    clearInterval(this.interval);
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
  },

  mounted() {
    this.startAutoSlide();
    this.getNode()
      this.intervalId = setInterval(this.getNode, 3000);
  }
}
</script>

<style scoped lang="scss">
.register-box {
  padding: 15px 10px !important;
}

.slider-container {
  position: relative;
  width: 100%;
  margin: 0 auto;
  max-width: 594px;
}

.slider {
  width: 100%;
  overflow: hidden;
}

.slide {
  width: 100%;
  height: auto;
  display: block;
}

.navigation {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.navigation button {
  width: 27px;
  height: 6px;
  margin: 0 5px;
  border-radius: 99px;
  border: none;
  background-color: #BABABA;
  cursor: pointer;
  outline: none;
  transition: all ease .4s;
}

.navigation button.active {
  background-color: #3300CC;
  width: 54px;
  cursor: default;
}

.slider-box {
  direction: ltr;

  .card-slider {
    width: 100%;
    padding: 20px;

    .child {
      width: 100%;
      height: 100%;
      padding: 32px;
      background: #fff;
      border-radius: 32px;
      box-shadow: 0 0 5px #ccc;

      .image{
        width: 100%;
        padding: 50px;
          height: 400px;
        img {
          border-radius: 16px;
          object-fit: contain;
          max-width: 100%;
          height: 100%;
        }
      }



      .text {
        width: 100%;
        padding: 15px 0;
        direction: rtl;

        p {
          color: #383838;
          font-size: 16px;
          line-height: 26px;
          margin-bottom: 0;
        }

        h2 {
          font-size: 24px;
          color: #1F2937;
          width: 100%;
          margin-bottom: 15px;
          font-weight: bold;
        }
      }

    }
  }
}

.circle-progress {
  width: 210px;
  height: 210px;
  position: relative;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;

  .percent {
    position: absolute;
    z-index: 1;

    span {
      font-size: 30px;
    }

    h3 {
      font-size: 40px;
      margin-bottom: 0;

    }
  }
}

.card-progress {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 0 auto;
  width: 280px;
  position: relative;

  .percent {
    position: relative;
  }

  svg {
    position: relative;
    width: 210px;
    height: 210px;
    transform: rotate(-90deg);
    z-index: 1;

    circle {
      width: 100%;
      height: 100%;
      fill: none;
      stroke: #f3f4f6;
      stroke-width: 10;
      stroke-linecap: round;

    }

    circle:last-of-type {
      stroke-dasharray: 625px;
      stroke-dashoffset: calc(625px - (625px * var(--percent)) / 100);
      stroke: url(#pattern);
      stroke-width: 10;
    }

  }

  .number {
    position: absolute;
    width: 100%;
    border-radius: 50%;
    background: #fff;
    bottom: 0;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 22px solid #f3f4f6;

    h3 {
      font-weight: 500;
      font-size: 2.5rem;


    }

  }

  .title h2 {
    margin: 25px 0 0;
  }


}


.progress-cycle {
  text-align: center;
  width: 100%;
  display: flex;
  justify-content: center;

  div {
    width: 286px;
    height: 286px;
    background: #fff;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    i {
      font-size: 64px;
      font-weight: 500;
      font-style: normal;
    }

    span {
      width: 100%;
      height: 100px;
      background-color: transparent;
      border-top-left-radius: 110px;
      border-top-right-radius: 110px;
      border: 10px solid gray;
      border-bottom: 0;
      position: absolute;
    }

  }
}

.form-box {
  min-width: 100%;
}

.loading-images {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  width: 140px;
  position: relative;
  margin: 0 auto;
  height: 240px;

  img {
    width: auto;
    position: absolute;
    top: 0;
    z-index: 3;
    opacity: 0;

    &.loading1 {
      animation-name: anim1;
      animation-duration: 4s;
      animation-delay: 1s;
      animation-iteration-count: infinite;
    }

    &.loading2 {
      top: 64px;
      z-index: 2;
      animation-name: anim2;
      animation-duration: 4s;
      animation-delay: .5s;
      animation-iteration-count: infinite;
    }

    &.loading3 {
      top: 127px;
      z-index: 1;
      animation-name: anim3;
      animation-duration: 4s;
      animation-iteration-count: infinite;
    }

    @keyframes anim3 {
      0% {
        top: 0;
        opacity: 0
      }
      50% {
        top: 127px;
        opacity: 1
      }
      100% {
        top: 160px;
        opacity: 0
      }
    }
    @keyframes anim2 {
      0% {
        top: 0;
        opacity: 0
      }
      50% {
        top: 64px;
        opacity: 1
      }
      100% {
        top: 127px;
        opacity: 0
      }
    }
    @keyframes anim1 {
      0% {
        top: 0;
        opacity: 0
      }
      50% {
        top: 2px;
        opacity: 1
      }
      100% {
        top: 120px;
        opacity: 0
      }
    }

  }
}

.bottom {
  margin-top: 7rem;
  padding: 0;

  h4 {
    font-weight: bold;
    font-size: 32px;
    margin-bottom: 20px;
  }
}

@media (max-width: 576px) {
  .register-box {
    padding: 15px !important;

    .bottom {
      padding: 10px 0;

      h4 {
        font-size: 24px;
      }
    }
  }

}
</style>
