<template>
  <div class="p-4">
    <div class="register-box text-center ">
      <img src="@/assets/img/complete.png" alt="complete">
      <h2>داشبورد شما آماده است.</h2>
      <div class="span">
        <em>30 روز</em> مهلت استفاده رایگان شما شروع شد!

      </div>
      <ul>
        <li>
          <h6><i class="fa-regular fa-link"></i>لینک دسترسی: </h6>
          <h5><button @click="copyURL" class="fa-regular fa-copy"></button>
           <span> {{ link }}</span> </h5>
        </li>
        <li>
          <h6> <i class="fa-regular fa-mobile"></i> شماره ثبت نام شده:</h6>
          <h5>09121234567 </h5>
        </li>
        <li>
          <h6> <i class="fa-regular fa-headphones"></i>شماره پشتیبانی:</h6>
          <h5>02133445566 </h5>
        </li>
      </ul>

      <a :href="link" class="btn btn-primary mx-auto">ورود به داشبورد</a>

    </div>
  </div>
</template>

<script>
import Cookies from 'js-cookie';

export default {
  name: "completedComponent",
  data() {
    return {
      apiToken: '',
      url: '',
      link: '',
    }
  },
  mounted() {
    this.getDashboard()
  },
  methods: {
    async copyURL() {
      try {
        await navigator.clipboard.writeText(this.link);
      } catch($e) {
        alert('Cannot copy');
      }
    },
    getDashboard() {
      let url = localStorage.getItem('urlPanel')
      let token = Cookies.get('token')
      if (token === undefined) {
        this.link = `${url}/auth/login`
      } else {
        this.link = `${url}/auth/check-login?token=${encodeURIComponent(token)}`
      }

    }
  }
}
</script>

<style scoped lang="scss">

.register-box{
  h2{
    font-size: 32px;
    color: #1F2937;
    font-weight: bold;
  }
  .span{
    font-size: 16px;
    em{
      font-style: normal;
      color: #0E9F6E;
    }
  }

  ul{
    max-width: 450px;
    margin: 15px auto ;
    padding: 0;
    li{
      display: flex;
      align-items: center;
      padding:15px 10px ;

      h6,h5{
        display: flex;
        align-items: center;
        font-size: 12px;
        color: #344054;
        font-weight: 300;
        margin: 0 ;
        i{
          margin-left: 6px;
        }
      }
      h5{
        margin-right: auto;
        font-size: 14px;
        color: #000;
        display: flex;
        align-items: center;
        span{
          max-width: 200px;
          text-overflow: ellipsis;
          overflow: hidden;
          display: block;
          text-wrap: nowrap;
          direction: ltr;
        }

        button{
          margin-left: 5px;
          color: #3300CC;
          transition: all ease .4s;
          &:hover{
            opacity: .7;
          }
        }
      }
    }
  }
}
.btn-primary{
  width: 450px;
  border-radius: 99px;
  padding: 16px 32px;
  background: #B4EF11;
  border: none;
  color: #1F2937;
  max-width: 100%;
}
</style>
